import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

export const PromotionBadge: FCC<{
  children: ReactNode;
  variant?: 'default' | 'promoted';
}> = ({ children, variant = 'default' }) => (
  <div
    className={cn(
      'px-2.5',
      'py-1',
      'rounded-full',
      'text-xs',
      'box-content',
      'flex',
      'items-center',
      'text-white',
      'w-fit',
      variant === 'promoted' ? 'bg-surface-top' : 'bg-action-primary-default'
    )}
  >
    {children}
  </div>
);
