import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryScheduleSlotsQueryVariables = Types.Exact<{
  coordinates?: Types.InputMaybe<Types.CoordinatesInput>;
}>;


export type DeliveryScheduleSlotsQuery = { readonly __typename: 'Query', readonly deliveryScheduleSlots: ReadonlyArray<{ readonly __typename: 'DeliveryScheduleSlot', readonly dateFrom: string, readonly dateTo: string, readonly available: boolean, readonly bookableUntil: string }> };


export const DeliveryScheduleSlotsDocument = gql`
    query DeliveryScheduleSlots($coordinates: CoordinatesInput) {
  deliveryScheduleSlots(coordinates: $coordinates) {
    dateFrom
    dateTo
    available
    bookableUntil
  }
}
    `;

/**
 * __useDeliveryScheduleSlotsQuery__
 *
 * To run a query within a React component, call `useDeliveryScheduleSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryScheduleSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryScheduleSlotsQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useDeliveryScheduleSlotsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>(DeliveryScheduleSlotsDocument, options);
      }
export function useDeliveryScheduleSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>(DeliveryScheduleSlotsDocument, options);
        }
export function useDeliveryScheduleSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>(DeliveryScheduleSlotsDocument, options);
        }
export type DeliveryScheduleSlotsQueryHookResult = ReturnType<typeof useDeliveryScheduleSlotsQuery>;
export type DeliveryScheduleSlotsLazyQueryHookResult = ReturnType<typeof useDeliveryScheduleSlotsLazyQuery>;
export type DeliveryScheduleSlotsSuspenseQueryHookResult = ReturnType<typeof useDeliveryScheduleSlotsSuspenseQuery>;
export type DeliveryScheduleSlotsQueryResult = Apollo.QueryResult<DeliveryScheduleSlotsQuery, DeliveryScheduleSlotsQueryVariables>;